import {
  Button,
  Grid,
  Typography,
  Icon,
  Divider,
} from '@mui/material';
import { lighten } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { Box, Stack, type SxProps, type Theme } from '@mui/system';
import {
  getIsExperiences,
  setIsExperiences,
} from '../menus/experiences/isExperiencesSlice';
import LogoTransparent from 'assets/common/emn-logo-transparent.svg';
import { useDispatch, useSelector } from 'react-redux';

// import { Icon } from 'core/utils';
import { Routes, DefaultRoute } from '../../../core/router';
import { useEffect } from 'react';

// export const AppBarHeight = 78;
export const APPBAR_HEIGHT = 78;
const EMNIconWidth = APPBAR_HEIGHT;

const toolboxStyle: SxProps<Theme> = theme => ({
  padding: '26px 41px 26px 61px',
  marginLeft: '-30px',
  marginRight: '30px',
  borderRadius: '0 0 25px 0',
  backgroundColor: 'primary.dark',
  '&:hover': {
    background: lighten(theme.palette.primary.dark, .1),
  },

  [theme.breakpoints.down('lg')]: {
    paddingLeft: '46px',
    marginRight: '20px',
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: '35px',
    marginRight: '10px',
  },

  '& .MuiTypography-topbar': {
    fontWeight: 600,
  },

  svg: {
    display: 'flex',
    color: 'wheat'
  },
  '& .toolboxIcon': {
    display: 'block',
  },
  '& .toolboxIconActivated': {
    display: 'flex',
  },
});

const navButtonStyle: SxProps<Theme> = {
  textDecoration: 'none',
  position: 'relative',
  zIndex: 5,
  padding: '0px',
  '&:hover': {
    background: 'transparent',
  },
};

const navMenuStyle: SxProps<Theme> = theme => ({
  display: 'flex',
  alignItems: 'center',
  padding: '33px 41px 19px',
  height: '78px',
  borderRadius: '0 0 25px 0',

  '&:hover .MuiTypography-topbar': {
    fontWeight: 600,
  },

  [theme.breakpoints.down('lg')]: {
    paddingLeft: '25px',
    paddingRight: '25px',
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
});

const navMenuActivatedStyle: SxProps<Theme> = {
  '&:after': {
    content: "''",
    position: 'absolute',
    background: '-webkit-linear-gradient(-45deg, rgba(255,0,0,.5)5%,rgba(0,70,201,1)100%)',
    height: '15px',
    width: '80%',
    top: 0,
    left: '10%',
    right: '10%',
  },
};

const mainLinkPrincipalStyle: SxProps<Theme> = {
  color: theme => theme.palette.primary.contrastText,
  marginLeft: 3,
  fontSize: 10,
  letterSpacing: '0.27em',
  textAlign: 'center',
  fontWeight: 500,
};

const mainLinkStyle: SxProps<Theme> = {
  color: theme => theme.palette.primary.contrastText,
  margin: 'auto',
  fontSize: 10,
  letterSpacing: '0.27em',
  textAlign: 'center',
  fontWeight: 500,
  paddingBottom: 1,
};

const wardIconTopStyle: SxProps<Theme> = {
  display: 'flex',
  background: 'linear-gradient(160deg, #ab0926, #0046c9)',
  borderRadius: '0 0 24px 24px ',
  paddingLeft: 2,
  '&:hover': {
    background: `linear-gradient(160deg, ${lighten('#ff0000', .1)}, ${lighten('#0046c9', .1)})`
  },
};

const wardStyle: SxProps<Theme> = {
  display: 'flex',
  width: 150,
  minWidth: 150,
  height: APPBAR_HEIGHT,
  borderRadius: '0 0 24px 0',
  backgroundColor: 'transparent',
  zIndex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  // '&:hover': {
  //   // backgroundColor: theme => lighten(theme.palette.primary.dark, .05),
  //   background: `linear-gradient(160deg, ${lighten('#ff0000', .1)}, ${lighten('#0046c9', .1)})`
  // },

  // [themeVariable.breakpoints.down('md')]: {
  //   display: 'none',
  // },

  h2: {
    fontSize: 20,
    fontWeight: 700,
    letterSpacing: '1px',
    margin: 0,
  },
};

const toolboxLinkStyle: SxProps<Theme> = {
  color: theme => theme.palette.primary.contrastText,
  // margin: 'auto',
  fontSize: 10,
  letterSpacing: '0.27em',
  textAlign: 'center',
  fontWeight: 600,
};

const appIconStyle: SxProps<Theme> = {
  height: APPBAR_HEIGHT,
  width: EMNIconWidth,
  // backgroundColor: 'transparent',
  zIndex: 2,
  // padding: 2,
};

const AppTopBarMenu = () => {
  const { t } = useTranslation<Namespace>('menu');
  const isExperiences = useSelector(getIsExperiences);
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    return history.listen(() => {
      dispatch(setIsExperiences(false));
      document.title = "EM Normandie";
    });
  }, [dispatch, history]);

  useEffect(() => {
    dispatch(setIsExperiences(isExperiences));
  }, [dispatch, isExperiences]);

  const isOpenedMenu = isExperiences;
  const isNews = (pathname === Routes.News) && !isOpenedMenu;
  const isInformations = (pathname === Routes.Informations) && !isOpenedMenu;
  const isContactForm = (pathname === Routes.ContactForm) && !isOpenedMenu;

  return (
    <>
      <Stack direction={'row'} >
        <Link
          to={DefaultRoute}
          style={{
            textDecoration: 'none',
            position: 'relative',
            zIndex: 6,
          }}
        >
          <Box sx={wardIconTopStyle}>
            <Icon sx={appIconStyle}>
              <img src={LogoTransparent} alt="EMN Logo" />
            </Icon>
            <Divider orientation="vertical" variant="middle" flexItem sx={{ borderColor: 'white', borderRightWidth: '.1px', marginBottom: 2, marginTop: 2, paddingLeft: 2 }} />
            <Stack direction="row" sx={wardStyle}>
              <Typography variant="h2" sx={toolboxLinkStyle}>
                {t('common:appname').toLocaleUpperCase()}
              </Typography>
            </Stack>
          </Box>
        </Link>

        <Button
          disableRipple
          disableFocusRipple
          onClick={() => {
            dispatch(setIsExperiences(!isExperiences));
          }}
          sx={navButtonStyle}
        >
          <Grid sx={[toolboxStyle]}>
            <Typography variant="topbar" sx={mainLinkPrincipalStyle}>
              {t('my experience')}
            </Typography>
          </Grid>
        </Button>

        <NavLink to={Routes.News}>
          <Button
            sx={[navButtonStyle, isNews ? navMenuActivatedStyle : {}]}
            disableRipple
            disableFocusRipple
          >
            <Grid sx={[navMenuStyle]}>
              <Typography variant="topbar" sx={mainLinkStyle}>
                {t('news')}
              </Typography>
            </Grid>
          </Button>
        </NavLink>
        <NavLink strict to={Routes.Informations}>
          <Button sx={[navButtonStyle, isInformations ? navMenuActivatedStyle : {}]}>
            <Grid sx={[navMenuStyle]}>
              <Typography variant="topbar" sx={mainLinkStyle}>
                {t('information')}
              </Typography>
            </Grid>
          </Button>
        </NavLink>
        <NavLink strict to={`${Routes.Business}/9789`}>
          <Button sx={[navButtonStyle, isContactForm ? navMenuActivatedStyle : {}]}>
            <Grid sx={[navMenuStyle]}>
              <Typography variant="topbar" sx={mainLinkStyle}>
                {t('contact')}
              </Typography>
            </Grid>
          </Button>
        </NavLink >
      </Stack>

    </>
  );
};

export default AppTopBarMenu;
