export enum Routes {
  Absences = '/absences',
  Alert = '/alert',
  Agenda = '/agenda',
  AgendaAdd = '/agenda/ajouter',
  AgendaEdit = '/agenda/modifier',
  Business = '/pages',
  Chat = '/chat',
  Classifieds = '/annonces',
  ClassifiedsAdd = '/mes-annonces/ajouter',
  ClassifiedsDetail = '/annonce',
  ClassifiedsEdit = '/mes-annonces/modifier',
  ContactForm = '/contact',
  HomePage = '/',
  Informations = '/informations',
  MyClassifieds = '/mes-annonces',
  News = '/actualites',
  NewsDetail = '/actualite',
  Notes = '/notes',
  PartnerUniversities = '/universites-partenaires',
  AroundTheWorld = '/around-the-world',
  Search = '/recherche',
  Student = '/profil',
  StudentAddJob = '/profil/pro/ajouter',
  StudentSettings = '/profil-modifier',
  Suggestion = '/suggestion',
}

export const DefaultRoute = Routes.HomePage;